import { DocumentType, DocumentTypes } from 'src/app/models/dwrm/document';
import { BaseModel, PaginatedResponse } from 'src/app/models/utils';

export interface Notification extends Partial<BaseModel> {
  userId: string;
  companyId: string;
  title: string;
  body: string;
  url: string;
  hasRead: boolean;
  isOnlyForRecipient: boolean;
  origin: NotificationOrigin;
}

export type PaginatedNotifications = PaginatedResponse<Notification[]>;

export const NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY =
  'notificationPermission';

export const NOTIFICATION_PERMISSION_DEFAULT = 'default';
export const NOTIFICATION_PERMISSION_GRANTED = 'granted';
export const NOTIFICATION_PERMISSION_DENIED = 'denied';

export interface NotificationOrigin {
  type: NotificationOriginType;
  name: NotificationOriginName;
}

export enum NotificationOriginType {
  ORD = 'ORD', // Order
  DOC = 'DOC', // Document
  CTR = 'CTR', // Contract
  MM = 'MM', // Matchmaking
  ADMIN = 'ADM', // Admin
}

export enum NotificationOriginName {
  // MM
  ENB_INQUIRY_MATCH = 'ENB_INQUIRY_MATCH',
  AEZ_NEW_OFFER = 'AEZ_NEW_OFFER',
  AEZ_ENB_REQUESTED_INFORMATION = 'AEZ_ENB_REQUESTED_INFORMATION',
  ENB_AEZ_PROVIDED_INFORMATION = 'ENB_AEZ_PROVIDED_INFORMATION',
  ENB_AEZ_ACCEPTED_OFFER = 'ENB_AEZ_ACCEPTED_OFFER',

  // CTR
  ENB_AEZ_CREATED_CONTRACT = 'ENB_AEZ_CREATED_CONTRACT',
  AEZ_ENB_ACCEPTED_CONTRACT = 'AEZ_ENB_ACCEPTED_CONTRACT',
  ENB_AEZ_REJECTED_CONTRACT = 'ENB_AEZ_REJECTED_CONTRACT',

  // ORD
  ENB_AEZ_TRIGGERED_PICKUP = 'ENB_AEZ_TRIGGERED_PICKUP',
  AEZ_ENB_SCHEDULED_PICKUP = 'AEZ_ENB_SCHEDULED_PICKUP',
  ENB_DAY_OF_PICKUP = 'ENB_DAY_OF_PICKUP',
  AEZ_ENB_CONFIRMED_PICKUP = 'AEZ_ENB_CONFIRMED_PICKUP',
  AEZ_ENB_RESCHEDULED_PICKUP = 'AEZ_ENB_RESCHEDULED_PICKUP',

  // DOC
  ENB_REMINDER_TO_UPLOAD_DE_NT = 'ENB_REMINDER_TO_UPLOAD_DE_NT',
  ENB_REMINDER_TO_UPLOAD_SU_NT = 'ENB_REMINDER_TO_UPLOAD_SU_NT',
  AEZ_ENB_HAS_UPLOADED_DE_NT = 'AEZ_ENB_HAS_UPLOADED_DE_NT',
  AEZ_ENB_HAS_UPLOADED_SU_NT = 'AEZ_ENB_HAS_UPLOADED_SU_NT',
  ENB_REMINDER_TO_UPLOAD_WE_NT = 'ENB_REMINDER_TO_UPLOAD_WE_NT',
  AEZ_ENB_HAS_UPLOADED_WE_NT = 'AEZ_ENB_HAS_UPLOADED_WE_NT',
  ENB_REMINDER_TO_UPLOAD_INV = 'ENB_REMINDER_TO_UPLOAD_INV',
  AEZ_ENB_HAS_UPLOADED_INV = 'AEZ_ENB_HAS_UPLOADED_INV',
}

export function fromString(type: string): NotificationOriginName | null {
  return (
    NotificationOriginName[type as keyof typeof NotificationOriginName] || null
  );
}

export function getNotificationOriginNameByType(
  documentType: DocumentType
): NotificationOriginName | null {
  switch (DocumentTypes[documentType]) {
    case DocumentTypes.DE_NT:
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_DE_NT;
    case DocumentTypes.WE_NT:
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_WE_NT;
    case DocumentTypes.SU_NT:
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_SU_NT;
    case DocumentTypes.INV:
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_INV;
    default:
      return null;
  }
}
